
.blender {
	height: 0;
	position: relative;
	z-index: 2;
	overflow: hidden;
	padding-bottom: ratio(1920px, 732px);
	background: $color-dark url(/images/local/blender/v-202103291600/0.jpg) no-repeat 50% 50% / cover;

	&__buttons {
		position: absolute;
		z-index: 3;
		right: 1em;
		bottom: 1em;
	}

	&__button {
		border-radius: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: transparent none;
		box-shadow: none;

		&:focus {
			box-shadow: none;
		}
	}


	@keyframes blend-in {
		from {
			display: block;
			opacity: 0;
		} to {
			display: block;
			opacity: 1;
		}
	}

	&__item {
		@include fill;
		background: {
			position: 50% 50%;
			repeat: no-repeat;
			size: cover;
		}

		&.-animating {
			animation: blend-in 800ms ease-out;
			animation-fill-mode: forwards;
		}
	}

	&__title,
	&__description {
		position: absolute;
		right: 1em;
		bottom: 1em;
		z-index: 51;
	}
}
